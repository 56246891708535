(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/sport-products/assets/javascripts/resolve-product-id.js') >= 0) return;  svs.modules.push('/components/lb-utils/sport-products/assets/javascripts/resolve-product-id.js');
"use strict";


const isServer = typeof exports === 'object';
const productIds = isServer ? require('./product-ids.es6') : svs.components.lbUtils.sportProducts.productIds;
const resolveProductIdFromShared = isServer ? require('trinidad-core').core.components.require('utils', 'products').resolveProductId : svs.utils.products.resolveProductId;
const resolveProductId = input => {
  if (typeof input === 'string') {
    input = input.replace(/\s/g, '').toLowerCase();
  }
  switch (input) {
    case 'casino':
    case productIds.CASINO:
      return productIds.CASINO;
    case 'livecasino':
    case 'live-casino':
    case productIds.LIVE_CASINO:
      return productIds.LIVE_CASINO;
    case 'poker':
    case productIds.POKER:
      return productIds.POKER;
    case 'bingo':
    case productIds.BINGO_M:
    case productIds.BINGO_CLASSIC_SINGEL:
    case productIds.BINGO_CLASSIC:
    case productIds.BINGO_PATTERN_SINGEL:
    case productIds.BINGO_PATTERN:
    case productIds.BINGO_VARIANT_SINGEL:
    case productIds.BINGO_VARIANT:
      return productIds.BINGO_M;
    default:
      return resolveProductIdFromShared(input);
  }
};
if (svs.isServer) {
  module.exports = resolveProductId;
} else {
  setGlobal('svs.components.lbUtils.sportProducts', {
    resolveProductId
  });
}

 })(window);