(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/sport-products/assets/javascripts/get-display-name.js') >= 0) return;  svs.modules.push('/components/lb-utils/sport-products/assets/javascripts/get-display-name.js');
"use strict";


const isServer = typeof exports === 'object';
const productIds = isServer ? require('./product-ids.es6') : svs.components.lbUtils.sportProducts.productIds;
const resolveProductId = isServer ? require('./resolve-product-id.es6') : svs.components.lbUtils.sportProducts.resolveProductId;
const getDisplayNameFromShared = isServer ? require('trinidad-core').core.components.require('utils', 'products').getDisplayName : svs.utils.products.getDisplayName;
const getDisplayName = anyInput => {
  const productId = resolveProductId(anyInput);
  if (!productId) {
    return '';
  }
  switch (productId) {
    case productIds.CASINO:
      return 'Casino';
    case productIds.LIVE_CASINO:
      return 'Live Casino';
    case productIds.POKER:
      return 'Poker';
    case productIds.BINGO_M:
    case productIds.BINGO_CLASSIC_SINGEL:
    case productIds.BINGO_CLASSIC:
    case productIds.BINGO_PATTERN_SINGEL:
    case productIds.BINGO_PATTERN:
    case productIds.BINGO_VARIANT_SINGEL:
    case productIds.BINGO_VARIANT:
      return 'Bingo';
    default:
      return getDisplayNameFromShared(productId);
  }
};
if (svs.isServer) {
  module.exports = getDisplayName;
} else {
  setGlobal('svs.components.lbUtils.sportProducts', {
    getDisplayName
  });
}

 })(window);