(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/sport-products/assets/javascripts/get-prod-name.js') >= 0) return;  svs.modules.push('/components/lb-utils/sport-products/assets/javascripts/get-prod-name.js');
"use strict";


const isServer = typeof exports === 'object';
const resolveProductId = isServer ? require('./resolve-product-id.es6') : svs.components.lbUtils.sportProducts.resolveProductId;
const productIds = isServer ? require('./product-ids.es6') : svs.components.lbUtils.sportProducts.productIds;
const getProdNameFromShared = isServer ? require('trinidad-core').core.components.require('utils', 'products').getProdName : svs.utils.products.getProdName;
const getProdName = input => {
  const productId = resolveProductId(input);
  if (!productId) {
    return '';
  }
  switch (productId) {
    case productIds.RACING:
      return 'hastar';
    default:
      return getProdNameFromShared(productId);
  }
};
if (svs.isServer) {
  module.exports = getProdName;
} else {
  setGlobal('svs.components.lbUtils.sportProducts', {
    getProdName
  });
}

 })(window);