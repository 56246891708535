(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/sport-products/assets/javascripts/server-export.js') >= 0) return;  svs.modules.push('/components/lb-utils/sport-products/assets/javascripts/server-export.js');
"use strict";


if (svs.isServer) {
  const productIds = require('./product-ids.es6');
  const resolveProductId = require('./resolve-product-id.es6');
  const getClassName = require('./get-class-name.es6');
  const getDisplayName = require('./get-display-name.es6');
  const getProdName = require('./get-prod-name.es6');
  module.exports = {
    productIds,
    resolveProductId,
    getClassName,
    getDisplayName,
    getProdName
  };
}

 })(window);