(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/sport-products/assets/javascripts/product-ids.js') >= 0) return;  svs.modules.push('/components/lb-utils/sport-products/assets/javascripts/product-ids.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const isServer = typeof exports === 'object';
const trinidad = isServer ? require('trinidad-core').core : svs.core;
const {
  productIds: ids
} = isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;

const productIds = _objectSpread(_objectSpread({}, ids), {}, {
  BINGO_M: 16,
  BINGO_CLASSIC_SINGEL: 71,
  BINGO_CLASSIC: 72,
  BINGO_PATTERN_SINGEL: 73,
  BINGO_PATTERN: 74,
  BINGO_VARIANT_SINGEL: 75,
  BINGO_VARIANT: 76,
  CASINO: 10001,
  LIVE_CASINO: 10002,
  POKER: 10003
});
if (svs.isServer) {
  module.exports = productIds;
} else {
  setGlobal('svs.components.lbUtils.sportProducts', {
    productIds
  });
}

 })(window);